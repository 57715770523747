@import "styles/resources.scss";

.container {
  width: $grid-width-s-mobile;
  margin: 0 auto;
  padding: $container-padding;
  box-sizing: border-box;

  @media screen and (min-width: $breakpoint-m-mobile) {
    width: $grid-width-mobile;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    max-width: $grid-width-desktop;
    width: auto;
  }
}
