@import 'styles/resources';

.financeInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $color-white;
  border: 1px solid $border-color;
  border-radius: 3px;
  margin-top: 70px;

  @media screen and (min-width: $breakpoint-mobile) {
    flex-direction: row;
    padding: 16px 48px;
  }

  .vehicleSection,
  .financeSection,
  .ctaSection {
    flex: 1 1 30%;
    text-align: center;
  }

  .financeSection {
    flex: 1 1 40%;
    text-align: center;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    .vehicleSection,
    .financeSection {
      text-align: left;
    }
  }
}

.financeLabelLease, .financeLabelFinance {
  font-size: 14px;
  text-transform: uppercase;

  @media screen and (min-width: $breakpoint-mobile) {
    @include fontFamilyBook(18px);
    text-transform: unset;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .financeLabelLease { color: $lease-color; }
  .financeLabelFinance { color: $buy-color; }
}

.vehicleSection {

  .seriesLabel {
    display: block;
    font-size: 40px;
    font-weight: 600; 
    letter-spacing: -0.55px;
    line-height: 36px;
    color: $primary-color;

    @media screen and (min-width: $breakpoint-mobile) {
      font-size: 36px;
    }
  }

  .seriesGradeLabel {
    font-size: 14px;
    line-height: 23.09px;

    @media screen and (min-width: $breakpoint-mobile) {
      @include fontFamilyBook(null,22px);
      color: $color-gray-46;
    }
  }
}



.ctaWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.date {
  color: $color-gray-46;
  text-align: center;
}