@import 'styles/resources';

.carBanner {
  background: url('../../images/backgroundImage.png') center center no-repeat;
  background-size: cover;
  height: 150px;
  text-align: center;

  @media screen and (min-width: $breakpoint-mobile) {
    height: 200px;
  }
}

.carJellyImage{
  margin: 85px auto 0;
  width: 300px;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-top: 71px;
    width: 440px;
  }
}