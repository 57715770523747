@import 'styles/resources.scss';

.modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  transition: $transition-timing;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: .5;
  z-index: 1030;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 100%;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 40px 16px;
  }
}

.content {
  flex: 1 1 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  position: relative;
  border: 1px solid rgba(0,0,0,0.2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  outline: 0;
  z-index: 1040;
  background-color: $background-color;
  width: 100%;
  overflow-y: auto;

  @media screen and (min-width: $breakpoint-mobile) {
    flex: 0 1 auto;
    max-width: 800px;
    width: auto;
  }

}

.header {
  box-sizing: border-box;
  border: 1px solid #CDD1D4;
  border-radius: 4px 4px 0 0;
  background-color: #F4F7FB;
  padding: 0 24px;
}

.headerContent {
  display: flex;
  align-items: center;
  position: relative;
  height: 67px;
}

.headerText {
  color: #152935;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.22px;
  line-height: 21px;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-black;
    font-weight: 600;
    border-radius: 55%;
    height: 40px;
    width: 40px;
    border: 2px solid $border-color;
    background-color: transparent;
    cursor: pointer;
  
    &::after {
      content: '✕';
      font-size: 21px;
    }
  }
}

.footer {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  // background-color: $white;
}

.body {
  padding: 24px;
  max-width: 400px;
  background-color: #fff;
}