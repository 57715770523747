@import "styles/resources.scss";

.raqForm {
  height: -webkit-fill-available;
  overflow: auto;
}

.financeTitle {
  display: block;
  font-size: 12px;
  font-weight: 100;
}

.label {
  display: flex;
  height: 26px;
  color: $color-black;
  font-size: 12px;
  margin-top: 15px;
}

.radio {
  position: relative;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: 1px solid $border-color;
  background: $color-white;
  margin-top: 0px;
  border-radius: 55%;
  top: 10px;

  &::before {
    content: " ";
    position: absolute;
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
    transition: all 0.1s;
    background: $color-white;
  }

  &:checked {
    background: $secondary-color;
    border: none;
    &::before {
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border-radius: 55%;
    }
  }
}

.contactOption {
  margin-left: 5px;
  font-size: 12px;
  display: inline-block;
  width: 37px;
}

.input {
  display: inline-flex;
  align-items: center;
  background-color: $color-white;
  width: 100%;
  height: 51px;
  font-size: inherit;
  padding-left: 5px;
  color: $color-black;
  border: 1px solid $border-color;
  border-radius: 0px;
  -webkit-appearance: none;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.preferenceContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.langPrefContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: sticky;
  &::after {
    content: "";
    position: absolute;
    top: 18px;
    right: 15px;
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-top: 2px solid $secondary-color;
    border-right: 2px solid $secondary-color;
    transform: rotate(134deg);
  }
}

.langPref {
  height: 51px;
  width: 157px;
  font-size: 12px;
  background: $color-white;
  color: $color-black;
  border: 1px solid $border-color;
  border-radius: 0px;
  -webkit-appearance: none;
  outline: none;
  padding-left: 5px;
}

.stateWrapper {
  width: 28%;
}

.state {
  width: 101px;
  margin-left: 5px;
}

.zipCode {
  width: 101px;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.commentsWrapper {
  padding-top: 10px;
  max-height: 100px;
  height: auto;
  font-size: 12px;
  cursor: pointer;
  
  button {
    color: $secondary-color;
  }

  textarea {
    width: 100%;
    height: 57px;
    outline: none;
    margin-top: 5px;
    resize: none;
    -webkit-appearance: none;
    border-radius: 0px;
    border: 1px solid $border-color;
    box-sizing: border-box;
  }
}

.editZipWrapper {
  width: 71%;
  text-align: end;
  position: relative;
  bottom: 4px;

  .edit {
    margin-left: 5px;
    transform: rotate(70deg);
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
  }

  .zipcode {
    -webkit-appearance: none;
    outline: none;
    border: none;
    text-align: end;
    color: $secondary-color;

    &.open {
      padding: 8px;
      width: 80px;
      color: $color-black;
    }
  }
}

.option {
  top: 0px;
}

.dealerList {
  margin-top: 5px;
}

.dealerDetailsContainer {
  display: inline-flex;
  align-items: flex-start;
  padding-top: 5px;
}

.dealerDetails {
  line-height: 16px;
}

.dealerName {
  font-size: 15px;
  display: inline-flex;
  padding-left: 5px;
}

.distance {
  width: 94px;
  color: $color-gray-59;
  font-size: 10px;
  padding-left: 5px;
}

.address {
  width: 275px;
  display: inline-flex;
  padding-left: 5px;
  font-size: 12px;
}

.viewMore {
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  
  button { 
    color: $secondary-color;
  }
}

.caret {
  font-size: 40px;
  height: 20px;
}

.quoteCta {
  height: 75px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.success {
  text-align: center;
  padding: 32px;
  max-width: 600px;

  h3 {
    font-size: 32px;
  }

  p {
    margin: 0;
  }

  button {
    margin: 32px 0 0 0;
  }
}

.error {
  color: $error-color;
}

.disclaimerWrapper {
  font-size: 9px;
  text-align: center;
  margin-top: 30px;
}

.disclaimerButton {
  color: $secondary-color;
  cursor: pointer;
}
