@import 'styles/resources.scss';

.budgetSlider {
  padding-top: 50px;
}

:global(.rc-slider-rail){
  height: 2px !important;
}

:global(.rc-slider-track) {
  height: 2px !important;
  background-color: $color-red-48f !important;

  @media screen and (min-width: $breakpoint-mobile) {
    height: 6px !important;
  }
}

:global(.rc-slider-tooltip) {
  height: 36px !important;
  width: 52px !important;
  position: fixed !important;
  display: flex !important;
}

:global(.rc-slider-tooltip-inner) {
  line-height: 1.5 !important;
  background-color: $color-black !important;
  min-width: 50px !important;
  height: 35px !important;
  border-radius: 150px/100px !important;
  font-size: 16px !important;
}

:global(.rc-slider-tooltip-placement-top) {
  padding: 0px !important;
}

:global(.rc-slider-tooltip-arrow) {
  border-top-color: $color-black !important;
  border-width: 5px 5px 0 !important;
  top: 34px !important;
}

.customHandle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 55px !important;
  height: 55px !important;
  cursor: -webkit-grab !important;
  background-color: $color-white !important;  
  border: solid 3px $border-color !important;
  border-radius: 50%;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  box-shadow: $box-shadow-01 !important;
  transform: translate3d(-38%, -40%, 0);

  @media screen and (min-width: $breakpoint-mobile) {
    width: 43px !important;
    height: 43px !important;
    transition:
      width $transition-timing,
      height $transition-timing;

    &:hover {
      width: 53px !important;
      height: 53px !important;
    }
  }

  .barWrapper {
    transform: rotate(90deg);
  }

  .bar {
    width: 12px;
    height: 1px;
    background-color: $color-black;

    & + .bar {
      margin-top: 3px;
    }
  }
}