@font-face {
  font-family: 'ToyotaTypeRegular';
  src:
    url('../src/components/fonts/toyota-type/ToyotaType-Regular.eot') format('embedded-opentype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Regular.ttf') format('truetype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Regular.woff2') format('woff2'),
    url('../src/components/fonts/toyota-type/ToyotaType-Regular.woff') format('woff'),
    url('../src/components/fonts/toyota-type/ToyotaType-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ToyotaTypeSemibold';
  src:
    url('../src/components/fonts/toyota-type/ToyotaType-Semibold.eot') format('embedded-opentype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Semibold.ttf') format('truetype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Semibold.woff2') format('woff2'),
    url('../src/components/fonts/toyota-type/ToyotaType-Semibold.woff') format('woff'),
    url('../src/components/fonts/toyota-type/ToyotaType-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ToyotaTypeBook';
  src:
    url('../src/components/fonts/toyota-type/ToyotaType-Book.eot') format('embedded-opentype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Book.ttf') format('truetype'),
    url('../src/components/fonts/toyota-type/ToyotaType-Book.woff2') format('woff2'),
    url('../src/components/fonts/toyota-type/ToyotaType-Book.woff') format('woff'),
    url('../src/components/fonts/toyota-type/ToyotaType-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}