@import 'styles/resources.scss';

.ctaContainer {
  margin-top: 40px;
}

.link {
  text-decoration: none;
}

.typeHeading {
  font-size: 16px;
  font-weight: 400;
}
