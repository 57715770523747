.loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &.noParent {
    min-height: 600px;
  }
}

.icon {
  background-image: url('../../images/toyota-red-load.svg');
  width: 80px;
  height: 80px;
}