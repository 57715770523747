.fadeInContainer {
  -webkit-animation: fadeinout 0.2s linear forwards;
  animation: fadeinout 0.2s linear forwards;

  @-webkit-keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}