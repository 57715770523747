@import "styles/resources.scss";

.headerInstruction {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
}

.budgetOptionsWrapper {
  margin-top: 15px;
  padding-bottom: 125px;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 100px;
  border: 1px solid $border-color;
  background: $background-color;
}

.sliderWrapper {
  width: 65%;
}

.budgetValue {
  height: 25px;
  font-size: 22px;
  float: right;
  position: relative;
  left: 85px;
  bottom: 25px;
}

.budgetValue:before {
  content: "$";
  font-size: 22px;
}

.perMonthLabel {
  font-size: 11px;
  clear: both;
  position: relative;
  left: 288px;
  bottom: 19px;
}

.option1,
.option2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.option1 {
  align-items: center;
}

.option2 {
  background: $background-color;
}

.cancelCta {
  color: $color-black;
  width: 30%;
  float: left;
  -webkit-appearance: none;
  //background: none;
  border: 1px solid $color-black;
  //top: 22px;
  left: 15px;
  font-size: 14px;
}

.ctaContainer {
  margin-left: 35px;
}

.labelContainer {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid $border-color;
  margin-top: 10px;
}

.catContainer {
  padding: 0px 5px;
}
