@import 'styles/resources.scss';

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: calc(.375em - 1px) 1.5em;
  height: 56px;
  appearance: none;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1.5;
  cursor: pointer;
  background: none;
  text-decoration: none;

  box-sizing: border-box;

  @media screen and (min-width: $breakpoint-mobile) {
    min-width: 148px;
    height: 61px;
    line-height: 61px; 
    padding: 0 8px;
  }
}

.redArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  background-color: $color-white;
  min-height: 56px;	
  border: none;
  box-shadow: $box-shadow;    
  padding: 0 16px;
  cursor: pointer;
  outline: none;
  
  &::after {
    content: '';
    position: absolute;
    right: 20px;
    display: inline-flex;
    width: 11px;
    height: 11px;
    border-top: 2px solid $secondary-color;
    border-right: 2px solid $secondary-color;
    transform: rotate(45deg);
  }

  &:hover {
    transform: scale(1.1);
    transition: $transition-timing;
  }
}

.defaultCta {
  background: $secondary-color;
  border: 1px solid $secondary-color;
  color: $color-white;
  //padding-right: 32px;
  font-size: 14px;
  font-weight: 600;
  outline: none;

  &:disabled {
    color: $input-disabled-text; 
    border: 1px solid $input-border;
    background: none;
    cursor: default;
  }
  &:focus {
    background: $color-red-38;
  }
}

.toggleArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  min-height: 56px;	
  border: none;
  padding: 0 24px 0 16px;
  font-family: $font-family;
  cursor: pointer;
  outline: none;
  
  &::after {
    content: '';
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 8px;
    border-right: 2px solid $secondary-color;
    border-bottom: 2px solid $secondary-color;
    transform: rotate(45deg);
    transition: transform $transition-timing;
  }

  &.toggled::after {
    transform: rotate(-135deg);
    margin: 4px 0 0 8px;
  }
}

.fullWidth {
  width: 100%;
}

.default {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  background-color: $color-white;
  min-height: 56px;	
  border: none;
  box-shadow: $box-shadow;    
  padding: 0 16px;
  cursor: pointer;
  outline: none;
}
