@import 'styles/resources.scss';

.trimOptionWrapper {
  padding-bottom: 30px;
}

.inBudgetWrapper,.almostInBudgetWrapper {
  min-height: 300px;
}

.almostInBudgetWrapper {
  padding-bottom: 8px;
}

.seriesLabel {
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: $color-black-20;
}

.optionsLabel {
  display: flex;
  height: 35px;
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}

.trimContainer {
  padding: 0px 5px;
  width: 350px;
}
