@import 'styles/resources.scss';

.header {
  font-size: 12px;
  color: $primary-color;
  background-color: $color-white;

  @media screen and (min-width: $breakpoint-mobile) {
    display: none;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  height: 60px;	
}

.headingLabel {
  display: inline-flex;
  align-items: center;
  height: 24px;
  position: relative;
  font-weight: 600;
}

.backBtn {
  position: relative;
  display: inline-block;
  background: transparent; 
  border: none;
  width: 40px;
  height: 40px;
  padding: 0px;
  cursor: pointer;

  & + .headingLabel {
    border-left: 1px solid $border-color;
    padding-left: 16px;
    width: 200px;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    left: 8px;
    width: 8px;
    height: 8px;
    border-left: 2px solid $color-red-48f;
    border-bottom: 2px solid $color-red-48f;
    transform: rotate(45deg); 
  }
}

.close {
  color: $color-black;
  float: right;
  font-weight: 600;
  border-radius: 55%;
  height: 40px;
  width: 40px;
  border: 2px solid $border-color;
  margin-right: 10px;
  position: relative;
  top: 15px;
  right: 15px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;

  span {
    font-size: 21px;
    position: relative;
    top: 3px;
  }
}
