.ctaContainer {
  padding: 20px 0 100px 0;
}

.nextCta {
  width: 275px;
  height: 60px;
  display: flex;
  margin: 0 auto;
}

.catContainer {
  padding: 0px 5px;
  width: 355px;
}