@import 'styles/resources';

.accordion {
  overflow: hidden;
  box-sizing: border-box;

  *, *::after, *::before {
    box-sizing: inherit;
  }
}

.accordionItem {
  overflow: hidden;
  border-bottom: 1px solid $border-color;

  &:first-child {
    border-top: 1px solid $border-color;
  }
}

.accordionHeader {
  .toggleSection {
    display: flex;
    align-items: center;
  }

  .toggleText {
    display: none;
    color: $color-gray-36;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;

    @media screen and (min-width: $breakpoint-mobile) {
      display: inline;
    }
  }

  .toggleIcon {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;

    &:before,
    &:after{
        content: "";
        position: absolute;
        background-color: $secondary-color;
        transition: transform $transition-timing;
    }

    /* Vertical line */
    &:before{
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        margin-left: -1px;
    }

    /* horizontal line */
    &:after{
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -1px;
    }
  }

  input {
    display: none;
    &:checked + .toggleIcon {
      &:before{ transform: rotate(90deg); }
      &:after{ transform: rotate(180deg); }
    }
  }

  label {
    position: relative; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 17px;
    line-height: 27px;
    height: 36px;
    font-family: $font-family-semi-bold;
    cursor: pointer;
  }
}

.accordionSection {
  @include fontFamilyBook();
  max-height: 0;
  transition: all $transition-timing;
  position: relative;
  top: 6px;
  &.expand {
    max-height: 200vh;
  }
}
