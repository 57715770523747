@import 'styles/resources.scss';

.heading {

  display: flex;
  justify-content: center;
  text-align: center;
}

.budgetWrapper {
  text-align: center;
}

.budgetSelector {
  height: 350px;
}

.budgetValue {
  height: 54px;
  font-size: 50px;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
  input {
    background: inherit;
    -webkit-appearance: none;
    text-align: center;
    border: none;
    font-size: 48px;
    width: 100px;
    outline: none;
    font-weight: 700;
    height: 60px;
  }
}

.budgetValue:before {
  content: '$';
  font-size: 47px;
  font-weight: 700;
}

.upTo {
  display: block;
  font-size: 12px;
}

.perMonthLabel {
  font-size: 15px;
  position: relative;
  top: 0px;
}

.ctaContainer {
  padding: 5px 0 100px 0;
}

.nextCta {
  width: 275px;
  height: 60px;
  display: flex;
  margin: 0 auto;
}

.disclaimer {
  color: $secondary-color;
  position: absolute;
  font-size: .6em;
  bottom: 10px;
  left: 50%;
  transform: translateX(65px);
}

.typeHeading {
  position: relative;
  font-size: 16px;
  font-weight: 400;
}