@import 'styles/resources';

.financeSection {
  margin-top: 10px;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 16px;
  }

 .financeContainer {
   display: flex;
 }

 .financeInfo {
  text-align: center;
  flex: 1 1 auto;

  @media screen and (min-width: $breakpoint-mobile) {
    text-align: left;  
  }

   & + .financeInfo {
     border-left: 1px solid $border-color;

     @media screen and (min-width: $breakpoint-mobile) {
       padding-left: 24px;
     }
   }
 }

 .financePayment {
   @include fontFamilyBook(35px, 36.94px);

   &Term {
     @include fontFamilyBook(14px, 25.6px);
     color: $tertiary-color;
     margin-left: 2px;
   }
 }

 .financeLabel, .financeLabelNoOffer {
   span:last-child { display: none; }

   @media screen and (min-width: $breakpoint-mobile) {
     span:first-child { display: none; }
     span:last-child { display: block; }
   }    
 }

 .financeLabel {
   @media screen and (min-width: $breakpoint-mobile) {
     color: $tertiary-color;
   }
 }

 .estimateDetails {
   color: $secondary-color;
   font-size: 13px;
   font-weight: bold;
 }
}

.otherDetails {
  display: flex;
  justify-content: center;
  margin: 8px -16px 0;

  @media screen and (max-width: $breakpoint-mobile) {
    justify-content: flex-start;
  }

  > * {
    @include fontFamilyBook(14px, 14px);
    padding: 0 16px;

    &:not(:first-child) {
      border-left: 1px solid $border-color;
    }
  }
  .value { font-weight: 600; }
}

.paymentWrapper {
  width: 50%;
  float: left;
  border-right: 1px solid $border-color;
}

.payment {
  text-align: left;
  margin-left: 8px;
}

.aprLabel {
  display: flex;
  padding-left: 30px;
}