@import 'styles/resources.scss';

.header {
  font-size: 12px;
  color: $primary-color;
  background-color: $color-white;

  @media screen and (min-width: $breakpoint-mobile) {
    display: none;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;	
}

.headingLabel {
  .numOfVehicles {
    font-weight: bold;
  }
}

.filterCta {
  height: 30px !important;
  width: 74px;
  border: 1px solid $primary-color !important;
  background: $color-white;
  padding: 0px !important;
}

.filterModal {
  background-color: $background-color;
}

.catContainer {
  padding: 0px 5px;
  width: 355px;
}

