@import 'styles/resources.scss';

.vehicleCategoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: $color-white;
  height: 72px;	
  border: none;
  box-shadow: $box-shadow-mobile;    
  padding: 0 16px;
  cursor: pointer;
  outline: none;
  font-family: $font-family;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
}

.cars {
  position: absolute;
  height: 71px;
  width: 150px;
  left: 0; 
  top: 10px;
  background-size: 115%;
  background-position: center center;
}

.carImage {
  background-size: 150%;
}

.truckImage {
  background-size: 140%;
  left: 4px;
}

.label {
  font-size: 16px;
  cursor: pointer;
  padding-left: 140px;
  line-height: 20px;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 19px;
  width: 20px;
  height: 20px;
  border: 1px solid $border-color;
}

.catSelected {
  background: $secondary-color;
  border: 1px solid $secondary-color;
}

.categorySelected {
  border: 2px solid $secondary-color;
  
  &::after {
    content: '';
    position: absolute;
    right: 24px;
    display: inline-flex;
    width: 10px;
    height: 6px;
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
    transform: rotate(135deg);
    top: 30px;
  }
}
