@import 'styles/resources';

.budgetDesktop {
  margin: 0 auto;
  padding: 80px 0;
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: inherit;
  }

  display: flex;
  justify-content: center;

  &.viewOffersOpen { padding-bottom: 0 }

  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 100px;  
    padding-right: 100px;  
  }
}

.budgetDesktopSection {
  min-height: 709px;
}

.desktopWrapperSection {
  height: 422px;
}

.desktopWrapper {
  width: 100%;
  background-color: $color-white;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .head {
    font-size: 36px;
    font-weight: bold;
    position: relative;
    top: 29px;
  }

  p {
    margin: 39px 0 3px 0;
  }
}

.topContainer {
  text-align: center;
  padding: 15px 100px 0;
}

.bottomContainer {
  text-align: center;
}

.sliderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 732px;
  width: 100%;
}

.sliderContainer {
  position: relative;
  flex: 1 1 auto;
  padding: 0 45px;
  margin-top: -13px; // center to input boxes
  z-index: 1;
}

.sliderLabel {
  position: relative;
  width: 104px;
  font-size: 12px;

  &::before {
    content: '$';
    position: absolute;
    top: -3px;
    left: 5px;
    font-size: 33px;
  }

  input {
    display: block;
    width: 100%;
    padding-left: 30px;
  }
}

.additionalOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: relative;
  bottom: 30px;
}

.additionalOptionsCta {
  display: flex;
  justify-content: center;
  position: relative;
  top: 1px;
  width: 255px;
  height: 46px;
  margin: 0 auto;
  background-color: $color-white;

  &.toggled {
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
}

.additionalOptionsBtn {
  min-height: 46px;
  height: 46px;
  line-height: 1;
}

.optionsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  transition: height $transition-timing;
  overflow: hidden;

  &.toggled {
    height: 77px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
}

.financingOptions, .vehiclefilter {
  display: none;
}

.categoryList, .financeTypes {
  display: flex;
  list-style-type: none;
  padding-left: 0px;
  li {
    margin-left: 26px;
  }
}

.financeTypes {
  display: flex;
  align-items: center;
  margin-left: 32px;
  padding-left: 0px;
  margin-right: 32px;
  border-left: 1px solid $border-color;
}

.checkbox {
  & + .checkbox {
    margin-left: 24px;
  }
}

.viewOffersCta {
  height: 60px;
  top: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  margin: -13px 0 0 0;
}

.disclaimer {
  color: $secondary-color;
  display: flex;
  position: relative;
  bottom: 24px;
  justify-content: flex-end;
  left: 270px;
  cursor: pointer;
  font-size: 0.6em;
}

.es {
  left: 222px;
}

.contentSection {
  max-height: 173px;
  z-index: 9;
}

.viewOfferCtaContainer {
  display: flex;
  justify-content: center;
  background: white;
  height: 135px;
  position: relative;
  bottom: 30px;
}
