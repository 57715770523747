@import "styles/resources.scss";

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

code {
  font-family: $font-family;
}

sup {
  vertical-align: top;
  font-size: 0.6em;
}

button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  line-height: 1;
  color: $color-black;

}
