@import 'styles/resources.scss';

.tiles {
  display: flex;
  justify-content: center;
  width: 100%;
}

.link {
  text-decoration: none;
}

.ctaContainer {
  margin-top: 40px;
  border: none;
  text-align: center;
  color: $secondary-color;
}

.typeHeading {
  font-size: 16px;
  font-weight: 400;
}
