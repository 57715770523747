@import 'styles/resources';

.input {
  height: 48px;
  line-height: 48px;
  font-size: 33px;
  font-family: $font-family;
  border: 1px solid $border-color;
  padding-bottom: 5px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}