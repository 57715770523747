@import "styles/resources.scss";

.optionItem {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: 7px;
  padding: 15px;
  transition: $transition-timing;
  box-sizing: border-box;
  font-family: $font-family;
  font-size: 12px;
  outline: 0;
  width: 97%;
  margin: 0 auto;
  margin-bottom: 15px;

  @media screen and (min-width: $breakpoint-m-mobile) {
    padding: 24px;
    width: 360px;
  }

  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  &.optionSelected {
    border: 2px solid $secondary-color;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }
}

.purchaseTypeLease,
.purchaseTypeFinance {
  font-size: 12px;
  font-family: $font-family-semi-bold;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.purchaseTypeLease {
  color: $lease-color;
}
.purchaseTypeFinance {
  color: $buy-color;
}

.seriesLabel {
  display: flex;
  font-size: 24px;
  font-weight: 600;
}

.carJelly {
  align-self: center;
  max-height: 90px;
}

.expDate {
  font-family: $font-family-book;
  font-size: 10px;
  line-height: 16px;
  padding-right: 10px;
}

.chbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border: 1px solid $color-black;
  border-radius: 3px;

  &.selected {
    background: $secondary-color;
    border: 1px solid $secondary-color;
    &::after {
      content: "";
      width: 10px;
      height: 6px;
      border-top: 2px solid $color-white;
      border-right: 2px solid $color-white;
      transform: rotate(135deg);
      position: absolute;
      top: 5px;
    }
  }
}

.content {
  font-family: $font-family-book;
  color: $color-black-20;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}

.optionPayment {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  position: relative;
  label {
    font-size: 12px;
    font-weight: 400;
    position: relative;
    color: $color-black-20;
    right: 3px;
    bottom: 5px;
  }
  button {
    color: $color-red-48f;
    font-size: 9px;
    position: relative;
    bottom: 6px;
  }
}

.optionPayment::before {
  content: "$";
  font-size: 20px;
}

.offerDetails {
  display: flex;
  font-size: 18px;
  line-height: 30px;
  color: $color-black-20;
  font-family: $font-family-semi-bold;
  position: relative;
  column-gap: 12px;

  .separator {
    height: 41px;
    width: 1px;
    align-self: flex-end;
    background: $color-gray-85;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    color: $color-gray-secondary;
    font-family: $font-family-book;
    padding-top: 4px;
  }
  .due {
    font-size: 18px;
    line-height: 30px;
  }
  .dollarSign {
    font-size: inherit;
  }
  .detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.startingLbl {
  font-size: 12px;
  color: $color-black;
  position: relative;
  letter-spacing: 1px;
}

.offerDetailsCta {
  display: flex;
  position: relative;
  padding: 0 15px 2px 5px;
  color: $color-black;
  text-decoration: none;
  font-weight: 600;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 7px;
    height: 7px;
    margin: -2px 0 0 0;
    border-right: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    transform: rotate(-45deg);
  }
}
