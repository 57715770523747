@import "styles/resources.scss";

.vehicleDetailsBackground {
  background-color: $color-white;
  padding-bottom: 88px;
}

.subHeader {
  display: none;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (min-width: $breakpoint-mobile) {
    display: flex;
  }

  &Back {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-left: 5px solid $secondary-color;
      border-top: 5px solid $secondary-color;
      transform: rotate(-45deg);
    }

    &Text {
      font-family: $font-family;
      margin-left: 8px;
      text-align: left;
      line-height: 1.2;

      span {
        display: block;
      }
      span:first-child {
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 16px;
      }
      span:last-child {
        font-size: 18px;
        letter-spacing: -0.24px;
        line-height: 15px;
      }
    }
  }

  &ToyCare {
    width: 50px;
    img {
      width: 100%;
    }
  }
}

.accordionTitle {
  @include fontFamilyBook(20px, 30px);
  height: 37px;
  margin: 36px 0 0 0;
}

.vehicleDetailsAccordion {
  width: auto;

  @media screen and (min-width: $breakpoint-mobile) {
    width: 475px;
    padding: 0 60px;
  }
}

.disclaimers {
  @include fontFamilyBook(10px, 15px);
  color: $color-gray-46;
  padding-left: 28px;
}
