@import "styles/resources";

.title {
  font-size: 36px;
  color: $color-black-21;
  text-align: center;
  line-height: 51px;
  font-weight: 100;
}

.disclaimers {
  padding: 16px;

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    background: $color-white;
    @media screen and (min-width: $breakpoint-mobile) {
      width: 720px;
      height: 137px;
    }

    li {
      display: inline-flex;
      font-size: 11px;
      width: 100%;
    }

    .index {
      color: $color-red-47;
      padding: 10px 15px;
      font-size: 15px;
      @media screen and (max-width: $breakpoint-mobile) {
        padding: 10px 5px;
      }
    }

    .description {
      margin: 40px 15px 0px 0px;
      @media screen and (max-width: $breakpoint-mobile) {
        margin: 15px 15px 0px 0px;
        width: 286px;
        font-size: 13px;
      }
    }
  }
}
