.ctaContainer {
  padding: 50px 0;
}

.nextCta {
  margin: 0 auto;
  width: 275px;
  height: 60px;
  display: flex;
}
