// colors
// naming convention: color-<colorType>-<luminosity>

$color-black: #000000;
$color-black-20: #333333;
$color-black-21: #343434;
$color-black-23: #3a3a3a;
$color-black-29: #4b4b4b;
$color-gray: #bbbbbb;
$color-gray-secondary: #58595b;
$color-gray-36: #5a5b5e;
$color-gray-46: #767676;
$color-gray-59: #979797;
$color-gray-85: #d8d8d8;
$color-gray-93: #ececed;
$color-gray-95: #f2f2f2;
$color-gray-96: #f6f6f6;
$color-white: #ffffff;

$color-red-38: #c00000;
$color-red-48e: #eb0a1e;
$color-red-48f: #ec081f;
$color-red-47: #d71921;

$color-green-25: #008000;
$color-green-27: #008a06;
$color-green-32: #00a468;
$color-green-36: #0fa970;

$color-blue-51: #3455db;

// color specifics
$primary-color: $color-black;
$secondary-color: $color-red-48f;
$tertiary-color: $color-gray-46;

$border-color: $color-gray-85; // default color for borders and separators
$error-color: $color-red-48f;
$background-color: $color-gray-96;
$lease-color: $color-blue-51;
$buy-color: $color-green-32;

// box shadows
$box-shadow-01: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$box-shadow-15: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
$box-shadow-20: 0 2px 6px 0 rgba(51, 51, 51, 0.2); // mobile budgetoption menu

// mobile-box-shadow
$box-shadow-mobile: $box-shadow-15;
$box-shadow: 0px 1px 2.97px 0.03px rgba(0, 0, 0, 0.09);

// font family
$font-family: "ToyotaTypeRegular", sans-serif;
$font-family-semi-bold: "ToyotaTypeSemibold", sans-serif;
$font-family-book: "ToyotaTypeBook", sans-serif;

// transition timing
$transition-timing: 0.2s cubic-bezier(0.3, 0.38, 0.15, 1);

// breakpoint
$breakpoint-m-mobile: 375px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 932px;

// inputs
$input-border: $color-gray-85;
$input-disabled: $color-gray-85;
$input-disabled-text: $color-gray-46;

// grid properties - taking from invision. not really using this
// because invision properties are random
$grid-columns: 12;
$grid-width-s-mobile: 320px;
$grid-width-mobile: 375px;
$grid-width-desktop: 980px;
$grid-column-width-mobile: 26.25px;
$grid-column-width-desktop: 60px;
$grid-gutter-width-mobile: 5px;
$grid-gutter-width-desktop: 16px;

$container-padding: 0 32px;
