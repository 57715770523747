@import '../../../styles/resources.scss';

.disclaimersModal {
  max-width: 750px;
  min-height: 302px;
  border-top: solid 4px $color-red-47;
  
  @media screen and (max-width: $breakpoint-mobile) {
    width: 354px;
    min-height: 364px;
    top: 87px;
    border: none;
    flex: 0;
  }
}