@import 'styles/resources.scss';

.linkWrapper {
  text-decoration: none;
}

.trimCta {
  height: 114px;
  width: 345px;
  background: $color-white;
  &::after {
    top: 35px;
  }
}

.trimWrapper {
  display: flex;
  height: 45px;
}

.trimName {
  font-size: 16px;
  text-align: left;
  min-height: 83px;
  width: 115px;
  position: absolute;
  top: 6px;
  display: flex;
  align-items: center;
  color: $color-black-20;
  font-family: $font-family-semi-bold;
  left: 25px;
}

.optionPayment {
  font-size: 20px;
  font-weight: 600;
  label {
    font-size: 20px;
    position: relative;
    right: 5px;
    font-weight: 300;
  }
}

.almost {
  margin-top: 8px;
}

.optionPayment::before {
  font-size: 20px;
}

.offerList {
  border-top: 1px solid $border-color;
  margin-top: 3px;
  min-height: 50px;
  height: auto;
  width: 275px;
  text-align: start;
}

.offerAmount, .offerName {
  font-size: 15px;
}

.offerName {
  padding-left: 10px;
}

.offerAmount::before {
  content: '$';
}

.date {
  font-size: 11px;
  display: block;
  text-align: start;
}

.content {
  display: grid;
  position: absolute;
  left: 145px;
  text-align: left;
  top: 12px;
  font-family: $font-family-book;
}

.offerDetails {
  display: flex;

  > div:not(:first-child) {
    border-left: 1px solid $tertiary-color;
    margin-left: 5px;
    padding-left: 5px;
  }
  .due {
    margin-right: 3px;
  }
  span{
    font-family: $font-family-semi-bold;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $border-color;
  margin-top: 80px;
  padding-top: 8px;
  width: 305px;
  height: 20px;

  .cta {
    color: $secondary-color;
    font-weight: bold;
    font-size: 10px;
    text-decoration: none;
    font-family: $font-family-semi-bold;
    position: relative;
    bottom: 3px;

    &::after {
      content: '';
      display: inline-flex;
      width: 8px;
      height: 8px;
      margin-left: 5px;
      border-top: 2px solid $secondary-color;
      border-right: 2px solid $secondary-color;
      transform: rotate(45deg);
      position: relative;
      top: 2px;
    }
  }
  span {
    color: $color-gray-46;
    font-size: 10px;
  }
}
