@import "styles/resources";

.vehicleResults {
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 24px;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

.container {
  display: flex;
  flex: 1 1 auto;
  width: 750px;
  justify-content: space-between;

  @media screen and (min-width: 1150px) {
    width: 1150px;
  }
}

.vehicleContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.labelContainer {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid $border-color;
  margin-top: 10px;
}

.totalVehicles {
  font-size: 18px;
  line-height: 16px;
  font-family: $font-family-book;
  position: relative;
  bottom: 20px;
}
