
@import 'styles/resources.scss';

.checkbox {
  display: inline-flex;
  align-items: center;
}

.input {
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: 1px solid $color-black;
  background: $color-white;
  margin-top: 0px;
  border-radius: 0px 0px 0px 0px;

  &.round {
    border-radius: 12px;

    &::before {
      border-radius: 12px;
    }
  }

  &::before {
    
    content: ' ';
    position: absolute;
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
    transition: all .1s;
    background: $secondary-color;
  }

  &:checked {
    background: $secondary-color;
    &::before {
      display: inline-flex;
      width: 10px;
      height: 6px;
      border-top: 2px solid $color-white;
      border-right: 2px solid $color-white;
      transform: rotate(135deg);
      top: 5px;
      left: 4px;
    }
  }
}

.label {
  margin-left: 4px;
}