@import 'styles/resources';

.summaryWrapper {
  min-height: 100px;
  height: auto;
  padding: 16px 24px;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 16px 64px;
  }
}

.summaryLabel {
  font-weight: 600;
  text-align: left;
  padding-top: 20px;
  display: none;

  @media screen and (min-width: $breakpoint-mobile) {
    display: block;
  }
}

.summaryPara {
  font-size: 20px;

  @media screen and (min-width: $breakpoint-mobile) {
    display: none;
  }
}

.bulletsList {
  color: $primary-color;
  min-height: 150px;
  list-style: none; /* Remove default bullets */
  margin: 0;
  font-size: 14px;
  line-height: 27px;
  padding-left: 0;

  li {
    display: flex;
    margin-bottom: 5px;

    &::before {
      content: "\2022"; 
      color: $secondary-color;
      font-weight: bold;
      display: inline-block;
      width: 28px;
      min-width: 28px;
    }

    a {
      text-decoration: none;
      color: $secondary-color;
    }
  }
}