@import "styles/resources";

.vehicleCard {
  padding: 0 9px;
  margin-bottom: 20px;

  width: calc(100% / 2);

  @media screen and (min-width: 1150px) {
    width: calc(100% / 3);
  }
}

.vehicleCardContent {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  padding: 24px;
  white-space: nowrap;
  border-radius: 7px;
  border: 1px solid $color-gray;
}

.header {
  display: flex;
  justify-content: space-between;
  flex: 0 0 45px;

  .startingAt {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 1px;
    padding-bottom: 4px;
    color: $color-black;
    font-family: $font-family-semi-bold;
    text-transform: uppercase;
  }

  .disclaimer {
    font-size: 9px;
    line-height: 12px;
    color: $secondary-color;
    cursor: pointer;
    position: relative;
    bottom: 2px;
    left: 2px;
  }

  .payment {
    font-size: 20px;
    line-height: 30px;
    position: relative;
    font-family: $font-family-semi-bold;
  }

  .paymentAlmost {
    font-weight: bold;
    font-size: 14px;
  }

  .months {
    font-size: 12px;
    line-height: 14px;
    color: $color-black-20;
    position: relative;
    bottom: 6px;
    left: 2px;
  }

  .outsideBudgetText {
    font-size: 12px;
    font-weight: bold;
    color: $primary-color;
  }
}

.expiration {
  font-size: 10px;
  line-height: 16px;
  position: relative;
  bottom: 7px;
  color: $color-gray-secondary;
  font-family: $font-family-book;

  &.hide {
    visibility: hidden;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

  .carJelly {
    width: 168px;

    img {
      width: 100%;
    }
  }

  .offerTypeContainer {
    width: 100%;
    position: relative;
    .financeType {
      &Buy,
      &Lease {
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 8px;
      }
      &Buy {
        color: $buy-color;
      }
      &Lease {
        color: $lease-color;
      }
    }

    .series {
      text-align: left;
      font-family: $font-family-semi-bold;
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 32px;
    }
  }

  .offerDetails {
    display: flex;
    font-size: 12px;
    position: relative;
    bottom: 5px;
    column-gap: 15px;
    align-self: flex-start;

    .detail {
      flex: 1 1 auto;
      font-size: 12px;
      line-height: 14px;
      color: $color-black-20;
      display: grid;
      width: auto;
    }

    .value {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 4px;
    }
  }
}

.separator {
  height: 41px;
  width: 1px;
  align-self: flex-end;
  background: $color-gray-85;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding-top: 16px;

  .link {
    cursor: pointer;
    font-size: 12px;
    font-family: $font-family-semi-bold;
    text-decoration: none;
  }

  .paymentDetailsButton {
    background-color: $color-black;
    line-height: 12px;
    padding: 9px 17px;
    padding-bottom: 12px;
    border-radius: 50px;
    color: $color-white;
  }

  .RaqButton {
    color: $color-black;
    display: flex;
    padding-right: 15px;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      width: 7px;
      height: 7px;
      margin: -2px 0 0 0;
      border-right: 2px solid $color-black;
      border-bottom: 2px solid $color-black;
      transform: rotate(-45deg);
    }
  }
}
