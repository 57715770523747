@import 'styles/resources.scss';

.tile {
  height: 128px;
  width: 163px;
  border: none;
  box-shadow: $box-shadow-mobile;
  background: $color-white;   
  outline: none;
  cursor: pointer;

  & + .tile {
    margin-left: 18px;
  }
}

.financeTile {
  border: 2px solid $secondary-color;
}

.financeLabel {
  font-weight: 600;
  font-size: 24px;
  font-family: $font-family;
}