@import 'styles/resources';

.error {

  padding: 16px;
  box-sizing: border-box;

  * { box-sizing: inherit;}

  .desktop {
    display: none;

    @media screen and (min-width: $breakpoint-mobile) {
      display: block;
    }
  }

  .mobile {
    @media screen and (min-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .title {
    text-align: center;
    font-size: 40px;
  }

  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  .wrapper {
    display: flex;
    width: 100%;
  }

  .mobileWrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    text-align: center;
  }

  .sectionLeft {
    flex: 1 1 40%;
    display: flex;
    align-items: center;
  }

  .sectionRight {
    flex: 1 1 60%;
    display: flex;
    align-items: center;
  }

  .message {
    font-size: 32px;
    font-weight: 300;
  }

  .instructionContainer {
    &::before {
      content: '';
      display: inline-block;
      width: 32px;
      height: 2px;
      background-color: $color-red-48f;
    }

    .instruction {
      margin: 0;
      padding: 16px 0 32px 0;
      font-size: 14px;

      @media screen and (min-width: $breakpoint-mobile) {
        padding: 8px 0 32px 0;
      }
    }
  }

  .image {
    width: 100%;
  }

  .cta {
    margin-top: 32px;

    @media screen and (min-width: $breakpoint-mobile) {
      margin-top: 0;
    }
  } 
}