@import 'styles/resources.scss';

.tabs {
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.tab1, .tab2, .tab3, .tab4 {
  width: 70px;
  margin-top: 22px;
  border-top: 1px solid $secondary-color;
  display: flex;
}

.tab2, .tab3, .tab4 { margin-left: 15px; }

.disableTab {
  border-top: 1px solid $input-disabled;
  pointer-events: none;
  margin-top: 22px;
}

.processStatus1, .processStatus2, .processStatus3 {
  content: '';
  display: inline-flex;
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-bottom: 2px solid $color-green-32;
  border-right: 2px solid $color-green-32;
  position: relative;
  right: 7px;
  top: 3px;
}

.processStatus2, .processStatus3 {
  left: 7px;
}

.tabLabel1, .tabLabel2, .tabLabel3, .tabLabel4 {
  vertical-align: sub;
  color: $secondary-color;
  font-size: 9px;
}

.tabLabel2, .tabLabel3, .tabLabel4 {
  padding-left: 16px;
}

.tabLabel4 {
  color: $color-black;
}

.disableLabel {
  color: $primary-color;
}

.currentTab {
  margin-top: 20px;
  border-top: 4px solid $secondary-color;
  -webkit-animation: fadeinout 2s linear forwards;
  animation: fadeinout 2s linear forwards;
}


